export default function (count, forms) {
    const n100 = count % 100;
    const n10 = count % 10;

    if ((n100 > 10) && (n100 < 21)) {
        return forms[2];
    } if ((!n10) || (n10 >= 5)) {
        return forms[2];
    } if (n10 === 1) {
        return forms[0];
    }

    return forms[1];
}
