<template>
  <div class="clients-list">
    <b-card
      no-body
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                debounce="500"
                :placeholder="t('dictionary.search')"
              />
            </b-input-group>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
                <b-spinner
                        v-if="importProcessing"
                        class="mr-2"
                        variant="primary"
                />
                <b-button
                  v-if="userRole.name === 'admin'"
                  v-b-modal.excel-client-modal
                  variant="white"
                  size="sm"
                  class="mr-2 btn btn-outline-primary"
                >
                  <feather-icon
                    class="text-primary"
                    size="20"
                    icon="ShareIcon"
                  />
                </b-button>
                <sorting-configure
                        :list="fields"
                        @changeSorting="(e) => fields = e"
                />
              <b-button
                v-if="$store.getters['user/permission']('clients:create')"
                variant="primary"
                :disabled="isProcessing"
                :to="{ name: 'client-add' }"
              >
                <span class="text-nowrap">
                  {{ t('clients.add') }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <BOverlay
        :show="isProcessing"
        opacity="0.8"
        variant="transparent"
      >
        <b-table
          ref="usersTable"
          class="position-relative min-height"
          hover
          selectable
          select-mode="single"
          :items="clients"
          responsive
          :fields="tableColumns"
          :per-page="0"
          :no-local-sorting="true"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          :empty-text="t('clients.table.empty-text')"
          @row-clicked="onRowClicked"
        >
          <!-- Column: User -->
          <template #cell(name)="{ item: client }">
            <div class="d-flex align-items-center">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="client.avatar"
                    :text="avatarText(client.name)"
                    variant="light-primary"
                  />
                </template>
                <b-link
                  :to="{ name: 'client-view', params: { clientId: client.id } }"
                  :class="isMobile
                  ? 'font-weight-bold d-block text-dark name-link mt-25'
                  : 'font-weight-bold d-block text-nowrap text-dark name-link mt-25'"
                  :style="isMobile ? 'width: 100px' : ''"
                >
                  {{ client.name }}
                </b-link>
              </b-media>
            </div>
          </template>

          <template #cell(facilities_count)="{ item: client }">
            <div v-if="client.last_facilities.length">
              <b-avatar
                v-for="facility in client.last_facilities"
                :key="facility.id"
                size="32"
                :src="facility.avatar"
                :text="avatarText(facility.name)"
                variant="light-primary"
                class="mr-25"
              />
              <b-avatar
                v-if="client.facilities_count > 3"
                size="32"
                :text="`+ ${client.facilities_count - 3}`"
                variant="light-primary"
              />
            </div>
            <div v-else>
              Нет объектов
            </div>
          </template>

          <template #cell(manager)="{ item: client }">
            <b-media
              vertical-align="center"
            >
              <template
                v-if="client.manager"
              >
                {{ client.manager.firstname }}
                {{ client.manager.surname ? client.manager.surname : '' }}
              </template>
              <template v-else>
                Нет ответсвенного
              </template>
            </b-media>
          </template>
        </b-table>
      </BOverlay>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            v-if="totalClients > 10"
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center
              justify-content-sm-start
              text-muted
            "
          >
            <span class="mr-1">{{ t('app.table.show-by') }}</span>
            <b-form-group style="width: 90px; margin: 0">
              <AppSelect
                v-model="pagination.perPage"
                style="width: 100%"
                :searchable="false"
                label="title"
                :clearable="false"
                :options="perPageOptions"
              />
            </b-form-group>
            <span class="ml-1">
              {{ t('app.table.of', { total: totalClients }) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalPages > 1"
              v-model="pagination.page"
              :total-rows="totalClients"
              :per-page="pagination.perPage"
              first-number
              last-number
              :limit="isMobile ? 1 : 5"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      v-if="userRole.name === 'admin'"
      id="excel-client-modal"
      ref="excelClientModal"
      centered
      title="Импорт из Excel"
      size="sm"
      hide-footer
      >
        <div>
          <p>Импорт из Excel поддерживает следующие форматы файлов .xls, .xlsx, .ods и .csv</p>
            <div class="d-flex justify-content-between align-items-center mt-1 mb-2">
                <BFormSelect
                        v-model="managerId"
                        :disabled="isLoading"
                        :options="[{text: 'Выберите ответственного', value: null, disabled: true},
                                 ...computedEmployees]"
                />
            </div>
            <div class="d-flex justify-content-between align-items-center mt-1 mb-2">
                <BFormFile
v-model="excelFile"
                           browse-text="Выбрать"
                           placeholder="Файл не выбран"
                           :disabled="isLoading"
                           accept=".xls, .xlsx, .ods, .csv"
/>
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <b-spinner
                        v-if="isLoading"
                        class="m-1"
                />
            </div>
          <div class="d-flex justify-content-between align-items-center mt-4 mb-2">
            <b-button
              variant="primary"
              :disabled="managerId === null || isLoading || excelFile === null"
              @click="excelImport"
            >
              <span class="text-nowrap">
                Загрузить
              </span>
            </b-button>
            <a
              id="close"
              class="text-primary cursor-pointer"
              style="text-decoration: underline"
              :href="exampleExcelUrl"
              @click="downloadExcel"
            >Скачать пример</a>
          </div>
        </div>
      </b-modal>
  </div>
</template>

<script>
import {
    BAvatar,
    BButton,
    BCard,
    BCol, BFormGroup,
    BFormInput, BFormSelect, BInputGroup,
    BFormFile,
    BInputGroupPrepend,
    BLink,
    BMedia, BModal, BOverlay,
    BPagination,
    BRow, BSpinner,
    BTable,
} from 'bootstrap-vue';
// import vSelect from 'vue-select';
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import {useLocalStorage} from '@vueuse/core';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import { useI18n } from '@/hooks/useI18n';
import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import { useRouter } from '@/hooks/useRouter';
import useCache from '@/hooks/useCache';
import {downloadExampleExcel, importExcel, importStatusExcel} from "@/store/clients/clients.api";
import wordForm from "@/utils/wordForm";
import SortingConfigure from '@/components/sortingConfigure/sortingConfigure.vue';

export default {
  name: 'ClientsList',
  components: {
    SortingConfigure,
      BFormSelect,
      BFormFile,
      BSpinner,
      BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BOverlay,

    AppSelect,
  },
  props: {
    noCache: Boolean,
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const { router } = useRouter();
    const { successToast, dangerToast } = useToast();
    const userRole = computed(() => store.getters['user/role']);

      const employees = ref([]);
      const manager = ref(null);
      const excelFile = ref(null);
      const managerId = ref(null);

      const getEmployees = async () => {
          const { result, data } = await store.dispatch('getManagersAsync');
          employees.value = data.map((val) => ({
              text: `${val.firstname} ${val.surname}`,
              value: val.id,
          }));
          if (!result) {
              //
          }
      };
      getEmployees();

      const updateClientManager = async (newManagerId) => {
          managerId.value = newManagerId;
      };

      const computedEmployees = computed(
          () => employees.value,
      );

      const newUserDisplayManager = computed(() => {
          const manager = employees.value.find(
              (employee) => employee.value === managerId.value,
          );
          return manager && manager.label ? manager.label : null;
      });

    const isMobile = ref(false);
    const excelClientModal = ref(null);

    const exampleExcelUrl = ref('');

      const downloadExample = async () => {
          const response = await downloadExampleExcel();
          exampleExcelUrl.value = response.data.response.url;
      };

      downloadExample();

      const downloadExcel = () => {
          excelClientModal.value.toggle('#close');
      };

    isMobile.value = store.state.app.isMobile;

    const roleVariantsMap = {
      admin: {
        description: 'Администратор',
        variant: 'success',
        icon: 'ServerIcon',
      },
      'manager-head': {
        description: 'Старший менеджер',
        variant: 'info',
        icon: 'SettingsIcon',
      },
      manager: {
        description: 'Менеджер',
        variant: 'info',
        icons: 'Edit2Icon',
      },
      accountant: {
        description: 'Бухгалтер',
        variant: 'primary',
        icon: 'Edit3Icon',
      },
      'service-head': {
        description: 'Руководитель сервиса',
        variant: 'success',
        icon: 'ServerIcon',
      },
      'service-engineer': {
        description: 'Инженер сервиса',
        variant: 'warning',
        icon: 'ServerIcon',
      },
    };

    const usersTable = ref(null);
    const tableColumnsArray = [
      { key: 'name', label: 'Название', sortable: true, visible: true },
      { key: 'facilities_count', label: 'Объекты', sortable: true, visible: true },
      { key: 'manager', label: 'Ответственный', sortable: true, visible: true},
    ];
    const fields = useLocalStorage('ClientListColumns', tableColumnsArray);
    const tableColumns = computed(() => fields.value.filter((i) => i.visible));

    const clients = ref([]);
    const totalClients = ref(0);

    const cache = useCache();
    const { noCache } = toRefs(props);
    const cacheParams = ref({
      prefix: 'clientsTable',
      storage: !noCache.value,
      url: !noCache.value,
    });
    const paginationPage = cache.create({
      defaultValue: '1',
      name: 'page',
      ...cacheParams.value,
      storage: false,
    });
    const paginationPerPage = cache.create({
      defaultValue: '25',
      name: 'perPage',
      ...cacheParams.value,
    });
    const pagination = ref({
      page: paginationPage,
      perPage: paginationPerPage,
    });
    const totalPages = computed(() => Math.ceil(totalClients.value / pagination.value.perPage));
    const perPageOptions = ['10', '25', '50', '100'];

    const searchQuery = cache.create({
      defaultValue: '',
      name: 'query',
      ...cacheParams.value,
      // storage: false,
    });
    const sortBy = cache.create({
      defaultValue: 'id',
      name: 'sortBy',
      ...cacheParams.value,
    });
    const isSortDirDesc = cache.create({
      defaultValue: false,
      name: 'isSortDirDesc',
      ...cacheParams.value,
      boolean: true,
    });

    const isProcessing = ref(false);
    const importProcessing = ref(false);
    const getClients = (payload) => store.dispatch('clients/getClients', payload);

    const getList = async () => {
      isProcessing.value = true;
      const { result, data } = await getClients({
        q: searchQuery.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        ...pagination.value,
      });
      isProcessing.value = false;

      if (result) {
        clients.value = data.list;
        totalClients.value = data.pagination.total;
      }
    };

    const getClientsList = () => {
      if (isProcessing.value) return;
      getList();
    };

    const page = computed(() => pagination.value.page);
    const perPage = computed(() => pagination.value.perPage);
    watch(page, () => {
      getList();
    }, { deep: true });
    watch(perPage, () => {
      pagination.value.page = 1;
      getList();
    });

    const isNeedToUpdateSortNavigation = ref(false);
    let sortNavigationDebounceTimer = null;
    watch([sortBy, isSortDirDesc], () => {
      clearTimeout(sortNavigationDebounceTimer);
      if (sortBy.value) {
        sortNavigationDebounceTimer = setTimeout(() => {
          isNeedToUpdateSortNavigation.value = true;
          sortNavigationDebounceTimer = null;
        }, 5);
      }
    });
    watch(isNeedToUpdateSortNavigation, (value) => {
      if (value) {
        getClientsList();
        isNeedToUpdateSortNavigation.value = false;
      }
    });

    watch(searchQuery, () => {
      getList();
    });

    const checkImportStatus = () => {
        if (userRole.value.name === 'admin') {
            importStatusExcel()
                .then((res) => {
                    if (res.data.response === false) {
                        return;
                    }
                    successToast('Импорт клиентов', wordForm(res.data.response.end_work,
                        [
                            t('import.minutes_v2', { min: res.data.response.end_work }),
                            t('import.minutes_v2', { min: res.data.response.end_work }),
                            t('import.minutes_v1', { min: res.data.response.end_work }),
                        ]));
                    importProcessing.value = true;
                })
        }
    };

    (async () => {
      isProcessing.value = true;
      try {
        await getList();
        await checkImportStatus();
      } catch (err) {
        dangerToast(
          t('errors.something-went-wrong.title'),
          t('errors.something-went-wrong.text'),
        );
      } finally {
        isProcessing.value = false;
      }
    })();
      const isLoading = ref(false);
      const excelImport = () => {
          const fd = new FormData();
          fd.append('file', excelFile.value);
          fd.append('manager_id', managerId.value);
          isLoading.value = true;
          importExcel(fd)
              .then(() => checkImportStatus())
              .catch(() => dangerToast('При загрузке файла произошла ошибка'))
              .finally(() => {
                  isLoading.value = false;
                  manager.value = null;
                  managerId.value = null;
                  excelClientModal.value.toggle('#close');
                  getClientsList();
              });
      };

    const onRowClicked = (client) => {
      router.push({ name: 'client-view', params: { clientId: client.id } });
    };

    return {
      t,
      isMobile,

      roleVariantsMap,
      userRole,
      fields,
      usersTable,
      tableColumns,

      clients,
      totalClients,

      pagination,
      totalPages,
      perPageOptions,

      searchQuery,
      sortBy,
      isSortDirDesc,

      isProcessing,
      getClientsList,

      onRowClicked,

      avatarText,

        excelImport,
        excelClientModal,
        exampleExcelUrl,
        downloadExcel,

        manager,
        excelFile,
        managerId,
        computedEmployees,
        getEmployees,
        updateClientManager,
        newUserDisplayManager,
        isLoading,
        importProcessing,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.clients-list {
  display: flex;
  flex-direction: column;
  > .card {
    flex: 1;
  }
  .badge-light-primary {
    color: #5E5873;
    .b-avatar-text {
      font-size: 11px !important;
    }
  }
}

.media-aside {
  @media(max-width: 600px) {
    margin-right: 1rem;
  }
}
</style>
