import { axiosIns } from '../api/index';

/**
 * Method to download example excel
 */
const downloadExampleExcel = () => axiosIns.get('client/excel/example');

/**
 * Method to import excel
 */
const importExcel = (params) => axiosIns.post('client/excel/import', params);
const importStatusExcel = () => axiosIns.get('client/excel/status');

export {
    downloadExampleExcel,
    importExcel,
    importStatusExcel,
};
